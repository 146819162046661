<template>
  <div>

    <div class="d-flex justify-content-center h-100 content">
      <b-row class="w-100" style="max-width: 1200px;">

        <b-col md="12">
          <h1 class="mt-4" style="font-size: 25px;">{{ 'frequently asked questions' | capitalizeAll }}</h1>
          <h2 style="font-size: 20px;">Questions about the program? We have the answers.</h2>
        </b-col>

        <b-col md="8" class="mt-4-lg">
          
          <div class="mt-4" id="faq-container">
            <div v-for="(item,index) in faq" :key="index">
              <a href="#" @click.prevent="toggleFaq(index)" class="no-select d-flex" style="padding-left: 40px;">
                <b-icon-chevronDown v-show="item.active" style="position: absolute; width: 25px; height: 25px !important; margin-right: 15px; margin-left: -30px;"></b-icon-chevronDown>
                <b-icon-chevronRight v-show="!item.active" style="position: absolute; width: 25px; height: 25px !important; margin-right: 15px; margin-left: -30px;"></b-icon-chevronRight>
                {{ item.question }}</a>
              <p v-show="item.active" class="pre-line" style="margin-left: 42px; margin-top: 10px; font-weight: 600;">{{ item.answer }}</p>
              <hr v-if="index != faqLength">
            </div>
          </div>

        </b-col>

        <b-col md="4">
          <b-img
            fluid
            src="@/assets/faq_2.jpg"
            style="margin-top:25px; padding: 10px;"
            class="photo"
          />
        </b-col>
      </b-row>
    </div>
  </div>

</template>

<script>

export default {
  name: 'faq',
  components: {
    
  },

  metaInfo: {
    title: 'Frequently Asked Questions'
  },

  data() {
    return {

      // 'active' will be true if the question is expanded
      faq: {
        1: {
          'question': 'How much of the marketing money previously funding DAAs stays in play to support Acura dealers?',
          'answer':   'All of it. The Regional Marketing funds will be used to buy regional media and fund your dealer marketing focusing on driving traffic to your store.',
          'active':   false,
        },
        2: {
          'question': 'What happens to the old DAA supplemental funding?',
          'answer':   'Supplemental budget will now be reinvested to support national & regional media buys.',
          'active':   false,
        },
        3: {
          'question': 'Will dealers be able to still support their own local events and partnerships within this new program?',
          'answer':   'Yes. We are working to create that ability into the new program. More to come on that topic.',
          'active':   false,
        },
        4: {
          'question': 'Can dealers in a market still band together their T3 funds to support an auto show or sponsorship?',
          'answer':   'We are looking at turnkey ways to pool T3 funds for local programs, or events. If there is an opportunity in a market, together with Zones, we can evaluate and decide a shared way to fund.',
          'active':   false,
        },
        5: {
          'question': 'Will my budget carry over month to month or once February comes will any unclaimed money go away?',
          'answer':   'They will be able to carry over budgets within a specified window. After that, they lose it.',
          'active':   false,
        },
        6: {
          'question': 'Will the top market DMAs have any input into the decisions made with regional support?',
          'answer':   'Media can be bought more efficiently when using 1 agency (RPA), but we want transparency and the support of key local T19 dealer leaders working with the zones when it comes to the spending.',
          'active':   false,
        },
        7: {
          'question': 'Can I use the new marketing allowance to cover CPO, Service, Fixed Ops, etc.?',
          'answer':   'Yes, however there is a 20% cap spend on supporting CPO and Parts and Service advertising.',
          'active':   false,
        },
        8: {
          'question': 'What percentage of former DAA funds, now Regional Marketing funds, need to be spent on digital marketing?',
          'answer':   '50% of the funds must support digital advertising within ADDP 2.0.',
          'active':   false,
        },
        9: {
          'question': 'Do the new Regional Marketing Funds cover the cost of my website and digital retailing fees?',
          'answer':   'No, the regional marketing funds only cover digital advertising media, non-digital media, i.e. (print, OOH, broadcast media, sponsorship and event costs.)',
          'active':   false,
        },
        10: {
          'question': 'What agency will manage the entire digital program?',
          'answer':   'Acura is evaluating agencies to be the overall partner to oversee the digital providers. A decision will be made in early January.',
          'active':   false,
        },
        11: {
          'question': 'What if my provider is not selected?',
          'answer':   'Dealers will use a provider that Acura certifies. Just like today, the providers are the best in the industry and will constantly be evaluated for performance. Acura is open to adding new providers, based on capabilities.',
          'active':   false,
        },
        12: {
          'question': 'What type of examples of digital advertising should I consider buying with the regional marketing funds?',
          'answer':   'The dealers will be required to use a provider that Acura selects. Just like today, the providers are the best in the industry and will constantly be evaluated for performance. Acura is open to adding new providers, based on capabilities.',
          'active':   false,
        },
        13: {
          'question': 'If I am advertising with Clickhere Digital and spending money for Google search and digital advertising, how do I know if it is at least 80% goes towards new?',
          'answer':   'Clickshare should be able to provide a breakdown by tactic (search, display, social, etc.) as well as by new or used.',
          'active':   false,
        },
        14: {
          'question': 'I am spending a set amount on New - can I just claim for that?',
          'answer':   'Dealers will be required to meet the minimum 50% spend on digital media in order to receive any of the regl mktg funds.',
          'active':   false,
        },
        15: {
          'question': 'For dealers that just signed new agreements or contracts with out-of-program vendors for a website, digital retailing, and dig ad vendors will we have a grace period for their contract to expire before they join ADDP, or is the expectation for them to break the contract in order to get on the program? Or will Acura help offset contract breaking fees?',
          'answer':   'We are aware of some of these providers already working with Acura stores, and those new providers will add in any existing dealerships into the ADDP 2.0 program.  J.D. Power also has a single point certification process that is possible to be leveraged in certain situations as well.',
          'active':   false,
        },
        16: {
          'question': 'Some dealerships and groups have proprietary tools for Digital Advertising and Digital Retailing – Example AutoNation has their own digital retailing tool “Autonation Express” that is implemented in all of the websites.  Are we asking the group to give up that investment and tool to sign up with one of our vendors?',
          'answer':   'J.D. Power SmartDigital works with a large list of providers, including those that power the technology behind many proprietary tools.  Their expertise in working with the white-labeled providers or in-house builds does not typically require a group, such as AutoNation, to change their processes and there are ways to complement Acura’s requirements while maintaining independence.  To see if your dealerships’ situation is eligible for single-point certification, please send a request through  info@acuradigitaldealer.com with the subject of “Proprietary Digital Retailing Tools”.',
          'active':   false,
        },
        17: {
          'question': 'Can dealers use their own creative or do we have to use Acura provided creative from AdBuilder?',
          'answer':   'To keep brand consistency, the regl marketing funds may only use the creative assets being provided on AdBuilder.',
          'active':   false,
        },
        18: {
          'question': 'Can my digital advertising provider use Adbuilder creative?',
          'answer':   'The providers will be required to use creative available on AdBuilder.',
          'active':   false,
        },
      },
    };
  },

  methods: {
    toggleFaq(index) {
      var current = this.faq[index].active;
      this.$set(this.faq[index], 'active', !current);
    },
  },

  computed: {
    faqLength() {
      return Object.keys(this.faq).length;
    },

    indexVideo() {
      return this.$refs.indexVideo;
    },
  },
}



</script>

<style>

#faq-container {
  max-width: 600px;
}

#faq-container a {
  color: #2d2d2d;
  max-width: 600px;
}

#faq-container a:hover {
  text-decoration: none;
  cursor: pointer;
  color: #e82c2a;
}

#faq-container i:hover {
  color: rgb(23, 92, 142);
}

.faq-expanded {
  padding-top: 10px;
  padding-right: 23px;
  padding-bottom: 23px;
  padding-left: 22px;
}

.no-select {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
  supported by Chrome and Opera */
}

.content {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {

  .content {
    margin-top: 0px;
    padding-top: 60px;
  }

}

</style>